import Head from 'next/head';
import React from 'react';

import { ContentContainer } from '@jernia/shared/components/content-container/content-container';

import Layout from '../components/layout/layout';
import Shortcuts from '../components/shortcuts';

export default function Home() {
  const shortcutItems = [
    {
      icon: '/icons/shortcuts/online-store.svg',
      title: 'B2H',
      button: {
        label: 'Gå til B2H',
        href: '/b2h',
      },
    },
    {
      icon: '/icons/shortcuts/calendar.svg',
      title: 'Utleie',
      button: {
        label: 'Gå til utleie',
        href: '/rental',
      },
    },
    {
      icon: '/icons/shortcuts/ticketing-system.svg',
      title: 'Reklamasjon',
      button: {
        label: 'Gå til reklamasjon',
        href: '/tickets',
      },
    },
    {
      icon: '/icons/shortcuts/customer.svg',
      title: 'Kundeklubb',
      button: {
        label: 'Gå til kundeklubb',
        href: '/customers',
      },
    },
  ];

  return (
    <>
      <Head>
        <title>Jernia Utleie</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <ContentContainer>
        <Shortcuts items={shortcutItems} />
      </ContentContainer>
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactNode) {
  return (
    <Layout greyBackground className="min-h-screen">
      {page}
    </Layout>
  );
};
